<template>

  <div class="index-box">
    <titleHeader></titleHeader>
    <div class="index-top">
      <div class="sy-header-l">
        <van-icon name="apps-o"  @click.stop="navShow=!navShow;" class="icon icon-apps-o"/>
      </div>
      <!-- <div class="sy-header-r">
        <van-icon name="search"  @click="toGo('/all_goods')" class="icon icon-search"/>
        <van-icon name="contact"  @click="toGo('/user')" class="icon icon-like-o"/>
      </div> -->
    </div>
    <van-swipe class="my-swipe banner" @click.stop=''  :autoplay="4000" indicator-color="white">
      <van-swipe-item v-for="(a,index) in bannerList" :key="index" @click="bannerToGo(a)">
        <div class="banner-img" :style="{ background: 'url('+a.cover+') bottom center'}"></div>
      </van-swipe-item>
    </van-swipe>
    
    <div class="index-bottom-bg" v-show="navShow" @click="navShow=false;"></div>
    <div class="index-bottom" @click.stop="navShow=true;" v-if="cp1List && cp1List.length>0">
      <div v-show="!navShow" class="index-nav">
        <div class="index-nav-type"  @click="category1Id=cp1List[1].id;select2Category()">
          <img :src="cp1List[1] | isNoneVal('imgAfter')" alt="" v-show="!navShow">
        </div>
        <div class="index-nav-type"  @click="category1Id=cp1List[0].id;select2Category()">
          <img :src="cp1List[0] ? cp1List[0].imgAfter :''" alt="" v-show="!navShow">
        </div>
        <div class="index-nav-type"  @click="category1Id=cp1List[2].id;select2Category()">
          <img :src="cp1List[2] ? cp1List[2].imgAfter :''" alt="" v-show="!navShow">
        </div>
      </div>
     
      <div  class="index-open-nav" :class="{'dq':navShow}">
        <p class="index-open-title">
          <span @click="classId=1" :style="{color: classId==1?'':'#808080'}">系列</span>
          <span @click="classId=2" :style="{color: classId==2?'':'#808080'}">类别</span>
        </p>
        <div v-show="classId==1">
          <div class="index-open-title2">
            <div class="index-nav-type"  v-for="(a,index) in cp1List" v-show="index<3" :key="index" @click="category1Id=a.id;select2Category()">
              <span :class="{'active':category1Id==a.id && navShow==true}">
                {{a.name}}
              </span> 
              
            </div>
          </div>

          <div v-if="category1Id==1" class="index-sub-box">
            <div class="index-sub-nav">
              <div class="index-sub-type" @click="toGo2('/all_goods',{categoryId:cp2List[0].id,title:cp2List[0].name})">
                <img :src="cp2List[0] ? cp2List[0].imgAfter : ''" alt=""  style="width:64%;">
                <p>{{cp2List[0] | isNoneVal('name')}}</p>
              </div>
            </div>
            <div class="index-sub-nav index-sub-nav2">
              <div class="index-sub-type" @click="toGo2('/all_goods',{categoryId:cp2List[2].id,title:cp2List[2].name})">
                <img :src="cp2List[2] ? cp2List[2].imgAfter : ''" alt="">
                <p>{{cp2List[2] | isNoneVal('name')}}</p>
              </div>
              <div class="index-sub-type index-sub-type2" @click="toGo2('/all_goods',{categoryId:cp2List[1].id,title:cp2List[1].name})">
                <img :src="cp2List[1] ? cp2List[1].imgAfter :''" alt="">
                <p>{{cp2List[1] | isNoneVal('name')}}</p>
              </div>
              <div class="index-sub-type" @click="toGo2('/all_goods',{categoryId:cp2List[3].id,title:cp2List[3].name})">
                <img :src="cp2List[3] ? cp2List[3].imgAfter : ''" alt="">
                <p>{{cp2List[3] | isNoneVal('name')}}</p>
              </div>
            </div>
            <div class="index-sub-nav">
              <div class="index-sub-type" @click="toGo2('/all_goods',{categoryId:cp2List[4].id,title:cp2List[4].name})">
                <img :src="cp2List[4] ? cp2List[4].imgAfter : ''" alt="" style="width:50%;">
                <p>{{cp2List[4] | isNoneVal('name')}}</p>
              </div>
            </div>
            
          </div>
          <div v-else class="index-sub-box">
            <div class="index-sub-nav" style="padding-top:60px;">
              <div class="index-sub-type" v-for="(a,index) in cp2List" :key="index" @click="toGo2('/all_goods',{categoryId:a.id,title:a.name})">
                <!-- <img :src="a.imgAfter" alt=""> -->
                <p>{{a.name}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="classId==2" class="index-class-box">
          <div class="index-sub-box" v-if="styleList && styleList.length>0">
            <div class="index-sub-nav">
              <div class="index-sub-type" @click="toGo2('/all_goods',{styleId:styleList[1].id,title:styleList[1].name})">
                <img :src="styleList[1] ? styleList[1].imgAfter : ''" alt="">
                <p>{{styleList[1] | isNoneVal('name')}}</p>
              </div>
              <div class="index-sub-type" @click="toGo2('/all_goods',{styleId:styleList[0].id,title:styleList[0].name})">
                <img :src="styleList[0] ? styleList[0].imgAfter : ''" alt="">
                <p>{{styleList[0] | isNoneVal('name')}}</p>
              </div>
            </div>
            <div class="index-sub-nav index-sub-nav2">
              <div class="index-sub-type" @click="toGo2('/all_goods',{styleId:styleList[2].id,title:styleList[2].name})">
                <img :src="styleList[2] ? styleList[2].imgAfter : ''" alt="">
                <p>{{styleList[2] | isNoneVal('name')}}</p>
              </div>
            </div>
            <div class="index-sub-nav index-class-sub-nav">
              <div class="index-sub-type" @click="toGo2('/all_goods',{styleId:styleList[3].id,title:styleList[3].name})">
                <img :src="styleList[3] ? styleList[3].imgAfter : ''" alt="">
                <p>{{styleList[3] | isNoneVal('name')}}</p>
              </div>
              <div class="index-sub-type" @click="toGo2('/all_goods',{styleId:styleList[5].id,title:styleList[5].name})">
                <img :src="styleList[5] ? styleList[5].imgAfter : ''" alt="">
                <p>{{styleList[5] | isNoneVal('name')}}</p>
              </div>
              <div class="index-sub-type" @click="toGo2('/all_goods',{styleId:styleList[4].id,title:styleList[4].name})">
                <img :src="styleList[4] ? styleList[4].imgAfter : ''" alt="">
                <p>{{styleList[4] | isNoneVal('name')}}</p>
              </div>
            </div>
          </div>
        </div>
        <p style="width:100%;text-align:center;" class="look-all" @click="toGo2('/all_goods',{all:'1',title:'全部尊品'})">
          <img src="../assets/images/index/btn.png" alt="">
        </p>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import {Toast} from "vant";
import {selectBanner,select1Category,select2Category,getProduct,selectStyleCategory} from "../utils/api";
export default {
  components: {},
  filters: {
    isNoneVal: function (obj, propertyName) {
      return obj && obj[propertyName] ? obj[propertyName] : '';
    }
  },
  data() {
    return {
      navShow:false,
      category1Id:1,
      classId:1,
      cp1List:null,
      cp2List:null,
      bannerList:null,
      styleList:null,
    }
  },
  
  created(){
      
    
  },
  mounted() {
    // setTimeout(()=>{
    //   this.initscroll();
    // },20)
    this.selectBanner();
    this.select1Category();
    this.select2Category();
    this.selectStyleCategory();
    
  },
  methods: {
    toGo2(go,data){
      if(!this.$cookies.get("Authorization")){
        Toast("您暂时没有权限查看,请联系客服");
        return false;  
      }
      this.$router.push({
        path:go,
        query:data
      });
    },
    bannerToGo(arr){
      console.log(arr)
      
      if(arr.type==1){
        getProduct({
          id:arr.productId
        })
        .then((res) => {
          this.toGo2('/detail',{id:res.data.productCategoryId,specifications:res.data.specifications})
        })
        
      }else{
        this.toGo2('/article',{id:arr.productId})
      }
    },
    selectStyleCategory(){
      console.log('输入中')
      selectStyleCategory()
      .then((res) => {
        console.log("【imgAfter】",res.data);
        this.styleList=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    selectBanner(){
      console.log('输入中')
      selectBanner()
      .then((res) => {
        console.log(res);
        this.bannerList=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    select1Category(){
      console.log('输入中')
      select1Category()
      .then((res) => {
        console.log(res);
        this.cp1List=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    select2Category(){
      this.cp2List=[];
      select2Category({
        categoryId:this.category1Id
      })
      .then((res) => {
        console.log(res);
        this.cp2List=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
<style lang="scss">
  .index-box{
    .van-swipe__indicators{
      bottom:auto;
      top:12px;
    }
    .banner{
      z-index: 1;
    }
  }
</style>
<style scoped lang="scss">
.wap-content{
  overflow: hidden;
  height: 100%;
  width: 100%;
}
@keyframes indexNavIcon {
  0% {
    transform: scale(0.01);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes indexNavIconOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes indexToShow2 {
  0% {
    width:0;
  }
  100% {
    width:88%;
  }
}
@keyframes indexToShow {
  0% {
    width:88%;
  }
  100% {
    width:0;
  }
}
@keyframes indexToHeight {
  0% {
    height:0;
  }
  100% {
    height:860px;
    margin-top: 50px;
  }
}
@keyframes indexToHeight2 {
  0% {
    height:860px;
  }
  100% {
    height:0;
  }
}
.index-box{
  width: 100%;
  height: 100%;
  overflow: hidden;
  .look-all{
      padding-bottom: 200px;
      .look-all-btn{
        width: 44%;
        margin:0 auto;
        position: relative;
        img{
          width: 100%;
        }
        span{
          width: 100%;
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          
          font-size:24px;
        }
      }

    }
  .index-top{
    position: absolute;
    top:110px;
    left:0;
    width: 100%;
    height: 110px;
    color:#fff;
    background: none;
    font-size: 48px;
    z-index: 1;
    .sy-header-l{
      position: absolute;
      left:0;
      top:50%;
      transform: translate(0%, -50%);
      
    }
    .icon-apps-o{
      
      margin-left: 30px;
    }
    .sy-header-r{
      position: absolute;
      right:0;
      top:50%;
      transform: translate(0%, -50%);
      
    }
    .icon-search{
      
      margin-right: 50px;
    }
    .icon-like-o{
      
      margin-right: 30px;
    }
  }
  .banner{
    width: 100%;
    height: 100%;
    .banner-img{
      width: 100%;
      height: 100%;
      background-size:cover!important;
    }
    
    .van-swipe-item{
      width: 100%;
      height: 100%;
      
    }
  }
  .index-bottom-bg{
    position: absolute;
    bottom:0%;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .index-bottom{
    padding-top: 80px;
    position: absolute;
    bottom:0%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index:1;
    width: 100%;
    height: auto;
    background: url('../assets/images/index/bg.png');
    background-position: top center;
    background-size:100% auto ;
    border-radius: 40px 40px 0 0;
    .index-nav{
      padding: 0px 12%;
      display: flex;
      .index-nav-type{
        padding: 30px 0 20px;
        flex:1;
        
        text-align: center;
        color: #1e1617;
        font-size: 24px;
        &:nth-of-type(2){
          img{
            margin-top: 0px;
          }
          
        }
        img{
          margin: 80px auto 0px;
          display: inline-block;
          width: 86%;
          height: auto;
          opacity: 0;
          animation:indexNavIconOut .4s .3s linear alternate forwards;
          animation-fill-mode:forwards;
        }
        img.indexNavIconOut{
          
        }
        p{
          margin-top: 0px;
        }
        .active{
          padding-bottom: 8px;
          border-bottom:1px solid #fcc;
        }
      }
    }
    .index-sub-box{
      height: 580px;
    }
    .index-class-box{
      margin: 100px 0 20px;
     
      .index-sub-nav2{
        margin:50px 0 60px;
      }
      .index-sub-nav{
        .index-sub-type{
          width: 33%;
          img{
            width: 44%;
          }
        }
      }
      .index-class-sub-nav{
        .index-sub-type{
          width: 20%;
          img{
            width: 74%;
          }
        }
      }
    }
    .index-sub-nav{
      padding: 0 4%;
      display: flex;
      justify-content: center;
      margin-bottom: 70px;
      &:last-child{
        margin-bottom: 0;
      }
      .index-sub-type{
        width: 26%;
        margin: 0 20px;
        text-align: center;
        img{
          width: 46%;
          height: auto;
        }
        p{
          color:#1e1617;
        }
      }
      .index-sub-type2{
        margin-top: 6px;
      }
    }
    .index-sub-nav{
      img:hover{
        opacity: 0.5;
      }
    }
    .index-border{
      animation:indexToShow .4s .4s linear alternate forwards;
      width: 0%;
      height: 1px;
      border-bottom:1px solid #999;
      margin: 0 auto 1px;
    }
    .index-border.dq{
      animation:indexToShow2 .4s .3s linear alternate forwards;
    }
    .index-open-nav{
      padding: 0 6%;
      animation:indexToHeight2 .3s linear alternate forwards;
      
      height: 0;
      overflow: auto;
      .btn{
        background: #fff;
      }
      .index-open-title{
        font-size: 24px;
        text-align: center;
        color:#f08300;
        margin-bottom: 10px;
        span{
          padding:  10px 50px;
        }
      }
      .index-open-title2{
        padding: 0px 14%;
        display: flex;
        .index-nav-type{
          padding: 60px 0 40px;
          flex:1;
          text-align: center;
          color: #808080;
          font-size: 24px;
          span{
            padding: 0 6px 10px;

          }
          .active{
            color:#f08300;
            
            border-bottom:1px solid #f08300;
          }
        }
      }
      .index-nav2{
        display: flex;
        justify-content:space-between; 
        flex-wrap: wrap;
        text-align: center;
        .index-nav-type{
          padding: 40px 0;
          width: 33.33%;
          text-align: center;
          color: #1e1617;
          font-size: 24px;
          img{
            margin: 0 auto;
            display: inline-block;
            width: 100px;
            height: auto;

          }
          p{
            margin-top: 20px;
          }
        }
      }
    }
    .index-open-nav.dq{
      animation:indexToHeight .3s linear alternate forwards;
    }
    .index-bottom-nav{
      margin: 50px 0 0;
      display: flex;
      height: 100px;
      line-height: 100px;
      background: #444;
      font-size: 28px;
      color:#fff;
      .index-bottom-nav-btn{
        flex:1;
        text-align: center;
      }
    }
  }
  .look-all{
    img{
      width: 36%;
      &:hover{
        opacity: 0.5;
      }
    }
  }
}
</style>
